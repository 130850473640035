// $blue: #252da4;
// $indigo: #7e2297;
// $purple: #583266;
// $pink: #8d3d3a;
// $red: #dc3545;
$orange: #fd7e14;
// $yellow: #ffc107;
// $green: #28a745;
// $teal: #22586e;
// $cyan: #1a2d5d;
// $dark: #0a1224;
$warning:       $orange;
$enable-responsive-font-sizes: true;