.visual-inner-text-area {
  top:20%;
  @media (min-width: 576px) {
    top:20%;
  }
  @media (min-width: 992px) {
    top:25%;
  }
  .card {
    background-color: rgba(255,255,255,0.7); border: 5px solid #df9f09; border-radius: 32px;
    padding: 1rem;
    @media (min-width: 576px) {
      padding: 1.5rem;
    }
    .card-body {
      padding: 0.5rem;
    @media (min-width: 576px) {
      padding: 1.5rem;
    }
    }
  }
  .visual-inner-message {
    font-weight: bold;
  }
}