body {
	font-family: 'Noto Sans JP', "Helvetica Neue",
		"Helvetica",
		"Hiragino Sans",
		"Hiragino Kaku Gothic ProN",
		"Arial",
		"Yu Gothic",
		"Meiryo",
		sans-serif;
	line-height: 1.75;
	padding-top: 56px;
}

// .wf-hannari {
// 	font-family: "Hannari";
// }
// .vegas-container,
// .vegas-slide {
// 	height: 500px;
// }


/* フリガナ表示調整 */
ruby {
	rt {
		font-weight: 300;
		font-size: 40%;
	}
}

/* ナビバータイトルのhover時下線無し */
.navbar-brand {
	a {
		&:hover {
			text-decoration: none;
		}
	}
	.brand-subtitle {
		font-size: 0.75em;
	}
}


/* SP時の左右内余白調整 */
.container {
	@media (max-width: 576px) {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
}

.btn-warning {
	color: #ffffff;
}

/* 各コンテンツ見出し：SP時センタリング */
.sec-title {
	@media (max-width: 576px) {
		text-align: center;
	}
}

/* ボタンをSP時にセンタリング */
.btns-center {
	@media (max-width: 576px) {
		text-align: center;
	}
}

/* 背景画像設定 */
.bg-lake {
	background-image: url(img/img-seta.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	@media (max-width: 576px) {
		background-attachment:inherit;
	}
}

.bg-river {
	background-image: url(img/img-seta03.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center bottom;
}

/* 問い合わせ先情報表のレスポンシブ化 */
.table-info-contact {
	@media (max-width: 576px) {
		tr {
			display: flex;
			flex-direction: column;
			th {
				padding-bottom: 0;
				&::after {
					content: '：';
				}
			}
			td {
				border-top: none;
				font-size: 1.2em;
			}
		}
	}
	caption {
		caption-side: top;
	}
}
